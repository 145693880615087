<template>
  <div class="body-main">
    <div class="banner-container">
      <div class="wrap">
        <a href="/jingping">
          <div class="logo-class"></div>
          <span class="top-desc">系统培养高薪岗位人才</span>
        </a>
        <h2>精品体系课</h2>
        <div class="desc clearfix">
          精选市场热门高薪职位，大厂从业大牛全程服务，更严苛的学习过程管理，多种云端工具保障学习效果
        </div>
      </div>
    </div>
    <div class="class-container clearfix">
      <div class="wrap">
        <div class="type-row">
          <div class="type-item js-type-item active" data-type="all">全部</div>
          <div class="type-item js-type-item" data-type="lingjichu">
            零基础就业
          </div>
          <div class="all-course-num js-course-num">共9门课程</div>
        </div>
        <div class="course-row">
          <div
          v-for="(v,i) in allData"
          :key="i"
            class="l card js-card js-show-num active lingjichu"
          >
            <a :href="`/fineVideo?id=${v.coursetypeid}`" class="js-zhuge-sale">
              <div
                class="img-con"
                 :style="{'backgroundImage':`url(${v.imagesmall})`}"
              >
                <div class="type"></div>
                <div class="timeout js-timeout-container">
                  <span>优惠倒计时：</span>
                  <span
                    class="js-timeout"
                    data-start="0"
                    data-end="0"
                    data-now="1643981996"
                  ></span>
                </div>
              </div>
            </a>
            <div class="text-con">
              <a
                class="title js-zhuge-sale"
                href=""
              >
               {{v.name}} </a>
            </div>
            <div class="info">
              <i class="imv2-set-sns"> </i>
              <span>{{v.learningnumber}}</span>
              <i class="imv2-clock"> </i>
              <span>214 小时</span>
              <i class="imv2-chat_bubble"> </i>
              <span>8160</span>
            </div>
            <div class="bottom-buy">
              <div class="icon l">优惠价</div>
              <div class="now-price l">¥ {{v.price}}</div>
              <a
                class="go-btn r js-zhuge-buy"
                :href="`#/cartOder?id=${v.coursetypeid}`"
                >立即购买</a
              >
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../ulti/ajax";
export default {
  data(){
    return{
      respdata:[],
      allData:[]
    }
  },
 async created(){
    this.respdata = (await api.getIndexAllData()).data.rows;
    console.log(this.respdata)
    this.allData = this.respdata.slice(0,8);
    console.log(this.allData)
   document.title='p8课堂';
  }
};
</script>

<style scoped>
.body-main {
  min-height: 750px;
  position: relative;
  background: #fff;
}

.banner-container {
  height: 220px;
  width: 100%;
  background-image: url(https://class.imooc.com/static/module/marketpage2020/img/index/banner3.png?t=1);
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  text-align: center;
}

.banner-container .wrap {
  width: 1152px;
  margin: 0 auto;
  height: 100%;
  padding-top: 75px;
  box-sizing: border-box;
  position: relative;
}

.banner-container .wrap .logo-class {
  width: 126px;
  height: 46px;
  background-image: url(https://class.imooc.com/static/module/marketpage2020/img/index/logo-class.png?t=3);
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  left: 0;
  top: 24px;
}

.banner-container .wrap .top-desc {
  height: 12px;
  position: absolute;
  left: 134px;
  top: 46px;
  font-size: 12px;
  color: #545c63;
  letter-spacing: 1px;
  line-height: 12px;
}

.banner-container .wrap h2 {
  font-size: 60px;
  font-weight: 700;
  color: #545c63;
  letter-spacing: 4.69px;
  text-align: center;
  line-height: 60px;
  margin-bottom: 20px;
}

.banner-container .wrap .desc {
  display: inline-block;
  font-size: 19px;
  color: #545c63;
  text-align: center;
  line-height: 24px;
  font-weight: 300;
}

.class-container {
  width: 100%;
  background: #fff;
}

.class-container .wrap {
  width: 1152px;
  height: 100%;
  margin: 0 auto;
}

.class-container .wrap .type-row {
  height: 84px;
  width: 100%;
  padding-top: 30px;
  box-sizing: border-box;
}

.class-container .wrap .type-row .type-item {
  display: inline-block;
  margin-right: 10px;
  height: 30px;
  padding: 0 16px;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 30px;
  color: #545c63;
  border-radius: 15px;
  transition: all 0.3s;
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  cursor: pointer;
}

.class-container .wrap .type-row .type-item.active,
.class-container .wrap .type-row .type-item:hover {
  font-weight: 700;
  color: #fff;
  background: #545c63;
}

.class-container .wrap .type-row .all-course-num {
  font-size: 12px;
  line-height: 30px;
  color: #9199a1;
  float: right;
}

.class-container .wrap .course-row {
  width: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -o-flex-wrap: wrap;
  flex-wrap: wrap;
}

.class-container .wrap .course-row .card {
  width: 348px;
  height: 326px;
  overflow: hidden;
  background: #fff;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  margin-right: 53px;
  position: relative;
  margin-bottom: 34px;
}

.class-container .wrap .course-row .card:nth-child(3n) {
  margin-right: 0;
}

.class-container .wrap .course-row .card.noshadow {
  box-shadow: none !important;
}

.class-container .wrap .course-row .card:hover .title {
  color: #1c1e20 !important;
  max-height: 88px !important;
  -webkit-line-clamp: 4 !important;
}

.class-container .wrap .course-row .card .img-con {
  width: 100%;
  height: 172px;
  position: relative;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.class-container .wrap .course-row .card .img-con .type {
  position: absolute;
  left: 0;
  top: 12px;
  width: 60px;
  height: 32px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(https://class.imooc.com/static/module/marketpage/img/index/type.png);
}

.class-container .wrap .course-row .card .img-con .type.type2 {
  background-image: url(/static/module/marketpage/img/index/type2.png);
}

.class-container .wrap .course-row .card .img-con .activity {
  height: 25px;
  background-image: url(/static/module/marketpage2020/img/index/label-618.png);
  background-size: cover;
  right: 0;
  position: absolute;
  width: 170px;
}

.class-container .wrap .course-row .card .img-con .timeout {
  display: none;
  position: absolute;
  right: 0;
  bottom: 0;
  padding-left: 11px;
  box-sizing: border-box;
  line-height: 20px;
  width: 175px;
  height: 20px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(/static/module/marketpage/img/index/timeout_bg.png);
}

.class-container .wrap .course-row .card .img-con .timeout span {
  font-size: 12px;
  color: #fff;
}

.class-container .wrap .course-row .card .img-con .tip {
  position: absolute;
  display: none;
  text-align: center;
  left: 20px;
  bottom: 8px;
  width: 106px;
  height: 20px;
  line-height: 20px;
  font-size: 12px;
  color: #fff;
  background: rgba(28, 31, 33, 0.3);
  border-radius: 4px;
}

.class-container .wrap .course-row .card .img-con .label-618 {
  position: absolute;
  top: 0;
  right: 0;
  width: 170px;
  height: 25px;
  background-image: url(/static/module/marketpage2020/img/index/label-618.png);
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  font-family: YouSheBiaoTiHei;
  font-size: 18px;
  color: #fff;
  padding-left: 20px;
  line-height: 25px;
  box-sizing: border-box;
}

.class-container .wrap .course-row .card .text-con {
  padding: 0 20px;
  padding-top: 16px;
  position: absolute;
  bottom: 94px;
  background: #fff;
  max-height: 88px;
}

.class-container .wrap .course-row .card .text-con .title {
  font-size: 14px;
  color: #1c1e20;
  color: #9199a1;
  line-height: 22px;
  max-height: 44px;
  display: block;
  transition: all 0.3s;
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.class-container .wrap .course-row .card .info {
  height: 16px;
  line-height: 16px;
  position: absolute;
  bottom: 66px;
  left: 0;
  padding: 0 20px;
  width: 100%;
  box-sizing: border-box;
}

.class-container .wrap .course-row .card .info i {
  font-size: 12px;
  color: #9199a1;
  padding-right: 2px;
}

.class-container .wrap .course-row .card .info span {
  font-size: 12px;
  color: #9199a1;
  padding-right: 14px;
}

.class-container .wrap .course-row .card .bottom-buy {
  position: absolute;
  bottom: 22px;
  left: 0;
  padding: 0 20px;
  height: 26px;
  width: 100%;
  box-sizing: border-box;
}

.class-container .wrap .course-row .card .bottom-buy .icon {
  border: 1px solid #f01515;
  border-radius: 4px;
  width: 56px;
  height: 28px;
  box-sizing: border-box;
  text-align: center;
  line-height: 26px;
  font-size: 12px;
  color: #f01515;
  margin-right: 10px;
}

.class-container .wrap .course-row .card .bottom-buy .icon2 {
  background: #f77e79;
  border-radius: 4px;
  width: 64px;
  height: 26px;
  text-align: center;
  line-height: 26px;
  font-size: 12px;
  color: #fff;
  margin-right: 8px;
}

.class-container .wrap .course-row .card .bottom-buy .icon3 {
  background: #9448eb;
  border-radius: 4px;
  width: 56px;
  height: 24px;
  text-align: center;
  font-family: PingFangSC-Regular;
  font-size: 14px;
  line-height: 24px;
  color: #fff;
  margin-right: 8px;
}

.class-container .wrap .course-row .card .bottom-buy .now-price {
  font-size: 14px;
  color: #f01515;
  font-weight: 700;
  margin-right: 8px;
  line-height: 30px;
}

.class-container .wrap .course-row .card .bottom-buy .old-price {
  font-size: 14px;
  color: #9199a1;
  margin-right: 8px;
  line-height: 30px;
  text-decoration: line-through;
}

.class-container .wrap .course-row .card .bottom-buy .go-btn {
  border: 1px solid #dbdfe3;
  border-radius: 13px;
  width: 80px;
  text-align: center;
  line-height: 26px;
  box-sizing: border-box;
  font-size: 12px;
  color: #1c1e20;
  cursor: pointer;
  transition: all 0.3s;
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
}

.class-container .wrap .course-row .card .bottom-buy .go-btn:hover {
  color: #000;
}

.class-container .wrap .course-row .card .bottom-buy .go-btn.presale {
  border: 1px solid #9448eb;
  color: #9448eb;
}
.class-container .wrap .course-row .card .img-con .type{
  height: 0;
}
</style>
